<template>
  <!-- index (Info Carousel) START -->
  <div class="index">

    <!-- fixed:info:top START -->
    <div class="fixed-info top">
      <img width="132" src="@/assets/images/logo-syreta-full.png" alt="syreta - logo" />
    </div>
    <!-- fixed:info:top END -->

    <swiper
      class="swiper"
      :options="swiperOption"
      ref="welcomeSwiper"
    >
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide
        v-for="(slide, slideIndex) in slides"
        :key="`slides-slide-${slideIndex}`"
      >
        <div
          class="subtitle"
          data-swiper-parallax="-100"
          v-html="slide.subtitle"
        ></div>
        <div
          :class="[
            'title',
            { 'big' : slideIndex === 0 }
          ]"
          data-swiper-parallax="-220"
          v-html="slide.title"
        ></div>
        <div
          class="text"
          data-swiper-parallax="-340"
          v-html="slide.description"
        ></div>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>

    <!-- fixed:info:bottom START -->
    <div class="fixed-info bottom">
      <div
        class="index-link"
        v-if="activeSlide < slides.length - 1"
        v-on:click="toSlide(slides.length - 1)"
        v-html="$t('login.skipheadline')"
      ></div>
      <div v-if="activeSlide === slides.length - 1">
        <router-link
          :to="'/register'"
          class="index-link"
          v-html="$t('login.registerheadline')"
        ></router-link>
        <router-link
          :to="'/login'"
          class="index-link"
          v-html="$t('login.loginheadline')"
        ></router-link>
      </div>
    </div>
    <!-- fixed:info:bottom END -->
  </div>
  <!-- index (Info Carousel) END -->
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      activeSlide: 0,
      slides: [
        {
          title: `${this.$t('login.future')}...`,
          subtitle: '',
          description: '',
        },
        {
          title: this.$t('welcome.card.headline'),
          subtitle: '',
          description: this.$t('welcome.card.description'),
        },
        {
          title: this.$t('welcome.online.headline'),
          subtitle: '',
          description: this.$t('welcome.online.description'),
        },
        {
          title: this.$t('welcome.door.headline'),
          subtitle: '',
          description: this.$t('welcome.door.description'),
        },
        {
          title: this.$t('welcome.smartphone.headline'),
          subtitle: '',
          description: this.$t('welcome.smartphone.description'),
        },
        {
          title: this.$t('welcome.final.headline'),
          subtitle: '',
          description: this.$t('welcome.final.description'),
        },
      ],
      swiperOption: {
        speed: 600,
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  methods: {
    toSlide(i) {
      this.$refs.welcomeSwiper.$swiper.slideTo(i, 1750);
    },
    onSlideChangeCustom() {
      this.activeSlide = this.$refs.welcomeSwiper.$swiper.activeIndex;
    },
  },
  mounted() {
    this.$refs.welcomeSwiper.$swiper.on('slideChange', () => {
      this.onSlideChangeCustom(this);
    });
  },
};
</script>

<style lang="scss" scope>
  h1 {
    &.index-h1 {
      color: black;
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 24px;
    }
  }
  a {
    &.index-link {
      display: block;
      color: var(--secondary-color);
      font-weight: bold;
      margin-top: 0.75rem
    }
  }
</style>
